// import Vue from 'vue'
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import permessi from "@/config/permessi";
import api from "@/services/api";
import { Component, Watch } from "vue-property-decorator";

const _ENDPONT = api.endpoint.NOTIFICATION.MESSAGES;

@Component({})
export default class MessaggiPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Messaggi.MESSAGGI];
	}
	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, editHide: true },
			// {
			// 	field: "fromUser",
			// 	title: this.$i18n.t("generico.fromUserId").toString(),
			// 	cell: "defaultCellTemplate",
			// 	headerCell: "myHeaderCellTemplate",
			// 	filterable: true,
			// 	export: true,
			// 	with: 150
			// },
			{
				field: "fromUserFullName",
				title: this.$i18n.t("generico.fromUserFullName").toString(),
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				width: 150
			},
			{
				field: "toUser",
				title: this.$i18n.t("generico.toUserId").toString(),
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				width: 300
			},
			{
				field: "toUserFullName",
				title: this.$i18n.t("generico.toUserFullName").toString(),
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				width: 150
			},
			{
				field: "message",
				title: this.$i18n.t("generico.messaggio").toString(),
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true
			},
			{
				field: "creationDate",
				title: this.$i18n.t("generico.dataInvioMessaggio").toString(),
				format: "{0:dd/MM/yyyy HH:mm:ss}",
				width: 150,
				type: "date",
				headerType: "daterange",
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "hasBeenRead",
				title: this.$i18n.t("generico.hasBeenRead").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				readonly: true,
				type: "boolean",
				filterType: "boolean",
				width: 120
			},
			{
				field: "readTime",
				title: this.$i18n.t("generico.readTime").toString(),
				format: "{0:dd/MM/yyyy HH:mm:ss}",
				width: 150,
				type: "date",
				headerType: "daterange",
				headerCell: "myHeaderCellTemplate"
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		this.sort = [{ field: "creationDate", dir: "desc" }];

		this.getData();
	}

	addNewRow() {
		let item = {} as any;
		item.numeroCoupon = 1;

		this.onEdit(item);
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${this.$i18n.t("generico.messaggio")}: ${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.messaggio")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}
}
